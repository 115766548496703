import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import useInteractive from '@bees-web/nfa-interactive-home/interactive-map'
import { pageTracking } from '@bees-web/nfa-interactive-global/actions/page'
import { withJoker, Page } from '../components'
import { IPage, IApplicationState } from '@bees-web/nfa-types'
import { useRouter } from 'next/router'
import { logger } from '../utils/logger'

export const Index = ({ name }: IPage) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const stateSelector = ({ home }: IApplicationState) => ({
    state: home,
  })
  const {
    authentication: { isAuthenticated, initialized },
    user: { country, language },
  } = useSelector((state: IApplicationState) => state.globals)

  useEffect(() => {
    if (initialized && !isAuthenticated) {
      dispatch(
        pageTracking((payload) => {
          const pageName = 'Guest Homepage'
          return {
            event: `${pageName} Viewed`,
            name: pageName,
            referrer: payload.referrer,
          }
        })
      )
    }
  }, [initialized, isAuthenticated, dispatch])

  if (!initialized) return null

  if (isAuthenticated) {
    let pageToRedirect = '/cms/index/home'

    if (process.env.IS_LOCAL === 'true' && country) {
      pageToRedirect = `${pageToRedirect}?country=${country}&language=${language}`
    }

    const logMessageIndexAuthenticatedParams = {
      env: process.env.STAGE,
      page: 'index',
      message: `Guest Homepage: country=${country} e language=${language}, pageRedirect:${pageToRedirect}`,
      isAuthenticated,
      pageToRedirect,
      country,
      language,
    }
    logger('info', logMessageIndexAuthenticatedParams)

    router.push(pageToRedirect)
    return null
  }

  const logMessageIndexParams = {
    env: process.env.STAGE,
    page: 'index',
    message: `Guest|Homepage => isAuthenticated: ${isAuthenticated}, country=${country} e language=${language}`,
    initialized,
    isAuthenticated,
    country,
    language,
  }
  logger('warn', logMessageIndexParams)

  return (
    <Page stateSelector={stateSelector} useInteractive={useInteractive}>
      {({ state, interactiveMap }) => {
        const {
          globals: {
            cache: { jokerFailed },
          },
        } = state

        return jokerFailed ? (
          <div>
            <h2>GuestHomepage Not Found</h2>
            <h3>
              <Link href="/">
                <a>Home</a>
              </Link>
            </h3>
          </div>
        ) : (
          <Page.Render
            name={name}
            state={state}
            interactiveMap={interactiveMap}
          />
        )
      }}
    </Page>
  )
}

export default withJoker(Index)
